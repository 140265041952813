import React, { useState, useContext } from 'react'
import SEO from '../../components/seo'
import gql from 'graphql-tag'
import { Mutation } from 'react-apollo'
import StoreContext from '../../context/StoreContext'
import ConnexionLayout from '../../components/account/ConnexionLayout'
import {
  BodyText,
  Button,
  FormInput,
  LargerHeading,
  FormError,
  FormLabel,
} from '../../styles'
import styled from 'styled-components'
import Navigation from '../../components/Navigation'
import { navigate } from 'gatsby'
import media from '../../styles/media'

const CUSTOMER_LOGIN = gql`
  mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
      customerAccessToken {
        accessToken
        expiresAt
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const CUSTOMER_PASSWORD_RESET = gql`
  mutation customerRecover($email: String!) {
    customerRecover(email: $email) {
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const OptionsCon = styled.div`
  margin: var(--gutter-m) var(--gutter-s);
  ${media.laptop`
    margin: var(--gutter-l) 150px;
  `}
`

const ResetCon = styled.div`
  margin: var(--gutter-m) var(--gutter-s);
  .resetButtons {
    display: flex;
  }
  ${media.laptop`
    margin: var(--gutter-l) var(--gutter-m);
    .resetButtons {
      display: flex;
    }
  `}
`

const AccountCon = styled.div`
  ${media.mobileL`
    display: grid;
    grid-template-columns: 1fr 0.3fr 1fr;
    grid-template-rows: 1fr;
    .divide {
      .left {
        width: 50%;
        height: 100%;
        border-right: 1px solid var(--light-grey);
      }
    }
  `}
`

const LoginForm = () => {
  const { setValue } = useContext(StoreContext)
  const [passwordForgot, setPasswordForgot] = useState(false)

  const [email, setEmail] = useState('')
  const [emailReset, setEmailReset] = useState('')

  const [messsageInfo, setMessageInfo] = useState('')

  const [password, setPassword] = useState(null)
  const handleCustomerAccessToken = value => {
    setValue(value)
  }

  const [error, setError] = useState(null)

  return (
    <>
      {passwordForgot ? (
        <section className="hero is-fullheight-with-navbar">
          <div className="hero-body">
            <div className="container">
              <div className="columns is-centered">
                <Mutation mutation={CUSTOMER_PASSWORD_RESET}>
                  {customerRecover => {
                    return (
                      <ResetCon>
                        <LargerHeading>Reset your Password</LargerHeading>
                        <BodyText className="black client">
                          We will send you an email to reset your password.
                        </BodyText>
                        <div className="field">
                          <FormLabel className="label" htmlFor="loginEmail">
                            Email
                          </FormLabel>
                          <div className="control">
                            <FormInput
                              className="input"
                              type="email"
                              id="loginEmail"
                              onChange={e => setEmailReset(e.target.value)}
                            />
                          </div>
                        </div>
                        <BodyText className="black client">
                          <br />
                          An email will be sent to reset your password. <br />
                          <br />
                        </BodyText>
                        <div className="field resetButtons">
                          <div className="control has-text-centered">
                            <Button
                              className="button"
                              onClick={() => {
                                customerRecover({
                                  variables: {
                                    email: emailReset,
                                  },
                                }).then(() => {
                                  setMessageInfo(
                                    "We've sent you an email with a link to update your password."
                                  )
                                  setPasswordForgot(false)
                                })
                              }}
                            >
                              Submit
                            </Button>
                          </div>
                          <br />
                          <br />
                          <div className="field">
                            <Button
                              className="clear"
                              tabIndex="0"
                              onClick={() => setPasswordForgot(!passwordForgot)}
                              onKeyDown={() => () =>
                                setPasswordForgot(!passwordForgot)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </ResetCon>
                    )
                  }}
                </Mutation>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <OptionsCon className="hero is-fullheight-with-navbar">
          <div className="hero-body">
            {messsageInfo && (
              <div class="notification is-success">
                <BodyText className="black client">{messsageInfo}</BodyText>
              </div>
            )}
            <AccountCon>
              <div>
                <LargerHeading className="bottomSpace">
                  Returning Customer
                </LargerHeading>
                <Mutation mutation={CUSTOMER_LOGIN}>
                  {customerLogin => {
                    return (
                      <>
                        <div className="field">
                          <FormLabel className="label" htmlFor="loginEmail">
                            Email
                          </FormLabel>
                          <div className="control">
                            <FormInput
                              className="input full"
                              type="email"
                              id="loginEmail"
                              onChange={e => setEmail(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="field">
                          <FormLabel className="label" htmlFor="loginPassword">
                            Password
                          </FormLabel>
                          <div className="control">
                            <FormInput
                              className="input full"
                              type="password"
                              id="loginPassword"
                              onChange={e => setPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        {error && (
                          <>
                            <FormError className="reg">{error}</FormError>{' '}
                            <br />
                            <br />
                          </>
                        )}

                        <Button
                          className="form-button input"
                          onClick={() => {
                            customerLogin({
                              variables: {
                                input: {
                                  email: email,
                                  password: password,
                                },
                              },
                            })
                              .then(result => {
                                if (
                                  result.data.customerAccessTokenCreate
                                    .customerUserErrors.length === 0
                                ) {
                                  handleCustomerAccessToken(
                                    result.data.customerAccessTokenCreate
                                      .customerAccessToken
                                  )
                                } else {
                                  setError('Incorrect email or password')
                                }
                              })
                              .catch(err => {
                                alert(err)
                              })
                          }}
                        >
                          Log in
                        </Button>
                        <br />
                        <br />
                        <div className="field">
                          <div
                            className="control has-text-centered"
                            role="button"
                            tabIndex="0"
                            onClick={() => setPasswordForgot(!passwordForgot)}
                            onKeyDown={() => setPasswordForgot(!passwordForgot)}
                          >
                            <BodyText className="blue bold forgot">
                              Forgot your password?{' '}
                            </BodyText>
                          </div>
                        </div>
                      </>
                    )
                  }}
                </Mutation>
                <br />
                <br />
              </div>
              <div className="divide">
                <div className="left"></div>
              </div>
              <div>
                <LargerHeading className="bottomSpace">
                  Create an account
                </LargerHeading>
                <BodyText className="black">
                  Don’t have an account? Create one now. <br />
                  <br />
                </BodyText>
                <Button
                  className="clear form-button full"
                  onClick={() => navigate('/../account/register')}
                >
                  Create account
                </Button>
              </div>
            </AccountCon>
          </div>
        </OptionsCon>
      )}
    </>
  )
}

const Login = () => {
  return (
    <>
      <SEO title="Login" />
      <Navigation />
      <ConnexionLayout log={false}>
        <LoginForm />
      </ConnexionLayout>
    </>
  )
}

export default Login
